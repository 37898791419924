.containerAbout {
    background-color: #640f17;
    color: #dad3c0;
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        margin-top: 65px;
        width: 60%;
        text-align: center;
        margin-bottom: 2%;
    }

    .source {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 95%;

        p {
            font-weight: 600;
        }
    }

    .members {
        width: 100%;
        margin-bottom: 40px;
        h2 {
            padding-top: 2%;
            padding-bottom: 2%;
            margin: auto;
        }
        .screenMembers {
            .container {
                display: flex;
                flex-wrap: wrap;
                margin: auto;
                font-weight: 600;

                .firstSection {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    margin: auto;
                    grid-gap: 50px;

                    .section {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                }

                .secondSection {
                    margin: auto;
                    margin-top: 30px;
                }

                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .memberName {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .member {
                        p {
                            width: 150px;
                            height: 40px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        img {
                            position: relative;
                            right: -25px;
                            width: 90px;
                        }
                    }
                }
            }
        }
        .mobileMembers {
            display: none;
        }
    }
}

@media screen and(max-width: 1200px) {
    .containerAbout {
        padding-bottom: 20px;
        h2 {
            font-size: 18px;
        }

        .source {
            h2 {
                font-size: 18px;
            }

            p {
                width: 80%;
                font-size: 14px;
            }
        }

        .members {
            .screenMembers {
                display: none;
            }

            .mobileMembers {
                display: flex;
                align-items: center;
                justify-content: center;
                .container {
                    display: flex;
                    flex-direction: column;
                    font-weight: 600;

                    .section {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        margin-top: 15px;
                        h3 {
                            font-size: 16px;
                        }
                        .memberName {
                            display: flex;
                            flex-wrap: wrap;

                            .member {
                                display: flex;
                                flex-direction: column;
                                p {
                                    width: 130px;
                                    text-align: center;
                                    height: 20px;
                                    font-size: 14px;
                                }
                                img {
                                    margin: auto;
                                    width: 55px;
                                }
                            }
                        }
                    }
                }
            }

            h2 {
                margin-top: 2%;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .containerAbout {
        .source {
            h2 {
                font-size: 18px;
            }

            p {
                width: 80%;
                font-size: 14px;
            }
        }

        .members {
            .screenMembers {
                display: none;
            }

            h2 {
                margin-top: 0;
                font-size: 18px;
            }

            .mobileMembers {
                .mobileMembers {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .container {
                        display: flex;
                        flex-direction: column;
                        font-weight: 600;

                        .section {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            margin-top: 15px;
                            h3 {
                                font-size: 16px;
                            }
                            .memberName {
                                display: flex;
                                flex-wrap: wrap;

                                .member {
                                    display: flex;
                                    flex-direction: column;
                                    p {
                                        width: 130px;
                                        text-align: center;
                                        height: 20px;
                                        font-size: 14px;
                                    }
                                    img {
                                        margin: auto;
                                        width: 55px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
