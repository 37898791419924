.repertory {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #640f17;
    color: #dad3c0;

    h2 {
        width: 60%;
        text-align: center;
        margin-top: 65px;
    }

    .containerRepertory {
        width: 95%;
        ul {
            list-style-type: none;

            li {
                font-weight: 600;
                &:before {
                    content: "-" + " ";
                }
            }
        }
    }

    .screenRepertory {
        display: flex;
        justify-content: start;
        flex-direction: column;
        width: 95%;
        margin-bottom: 40px;

        h2 {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .mobileRepertory {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .repertory {
        .screenRepertory {
            display: none;
        }

        .mobileRepertory {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;

            h2 {
                font-size: 18px;
            }

            ul {
                width: 90%;
                font-size: 14px;

                li {
                    width: 90%;
                }
            }
        }
    }
}
