.audios {
    width: 1580px;
    h3 {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .screenAudios {
        .left {
            display: flex;
            flex-direction: column;
            float: left;

        }

        .right {
            display: flex;
            flex-direction: column;
            float: right;
        }

        .container {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 700px;
            height: 400px;
            color: #fff5eb;
        }

        .icon {
            position: absolute;
            top: 1rem;
            right: 1rem;
            color: #fff5eb;
        }
    }

    .mobileAudios {
        display: none;
    }
}

@media screen and (max-width: 1440px) {
    .audios {
        width: 90%;
        .screenAudios {
            display: none;
        }

        .mobileAudios {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-top: 0;
            width: 75%;
            margin: auto;
            overflow: auto;

            h3 {
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        .icon {
            position: absolute;
            cursor: pointer;
            color: #fff5eb;
        }
    }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
.audios {
    width: 85%;
}
}

