.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #640f17;
    color: #dad3c0;
    margin-bottom: -19px;

    .title {
        width: 60%;
        text-align: center;
        margin-top: 65px;
    }

    .formulario {
        background-color: #721720;
        padding-right: 7%;
        padding-left: 7%;
        padding-bottom: 2%;
        padding-top: 3%;
        border-radius: 10px;
        margin-top: 2%;
        margin-bottom: 2%;

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            input {
                width: 160%;
                height: 30px;
                border: none;
                border-radius: 5px;
                margin-bottom: 20px;
            }

            textarea {
                width: 160%;
                height: 100px;
                border: none;
                border-radius: 5px;
                resize: none;
            }

            button {
                margin-top: 15%;
                padding: 5%;
                border: none;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 600;
                color: #8b2a25;
                background-color: #fff5eb;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .contact {
        h2 {
            font-size: 20px;
        }
        .formulario {
            padding-bottom: 4%;
                background-color: #721720;
                padding-right: 12%;
                padding-left: 12%;
                padding-bottom: 2%;
                padding-top: 8%;
                border-radius: 10px;
                margin-top: 2%;
            form {
                input {
                    width: 110%;
                }

                textarea {
                    width: 110%;
                }

                button {
                    margin-top: 10%;
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .contact {
        h2 {
            font-size: 20px;
        }
        .formulario {
            padding-bottom: 4%;
                background-color: #721720;
                padding-right: 12%;
                padding-left: 12%;
                padding-bottom: 2%;
                padding-top: 5%;
                border-radius: 10px;
                margin-top: 2%;
            form {
                input {
                    width: 140%;
                }

                textarea {
                    width: 140%;
                }

                button {
                    margin-top: 10%;
                    font-size: 12px;
                }
            }
        }
    }
}


