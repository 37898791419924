.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: #640f17;
    border-bottom: 2px solid #721a22;
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 60px;
    font-size: 20px;

    img {
        display: flex;
        margin: 15px;
        cursor: pointer;
    }

    a {
        margin: 10px;
        text-decoration: none;
        color: #dad3c0;
        cursor: pointer;
        &:hover {
            transition: 0.3s;
            color: rgb(168, 76, 83);
        }
    }

    ul {
        display: flex;
        list-style: none;
        justify-content: space-between;
        transition: 0.3s all;
        li {
            margin: 15px;
            display: inline;
            align-items: center;
        }
    }

    .icon {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .navbar {
        position: fixed;

        ul {
            display: flex;
            flex-direction: column;
            text-align: center;
            position: absolute;
            top: 40px;
            right: 0;
            width: 100%;
            background-color: #640f17;
            border-top: 2px solid #721a22;
            border-bottom: 2px solid #721a22;

            li {
                font-size: 15px;
            }
        }

        .icon {
            display: flex;
            position: absolute;
            right: 15px;
            cursor: pointer;
            color: #dad3c0;
        }
    }
}
