.audiovisual {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #dad3c0;
    background-color: black;

    h2 {
        width: 60%;
        text-align: center;
        margin-top: 65px;
    }
}


@media screen and (max-width: 1200px) {
    .audiovisual {
        .title {
            font-size: 18px;
        }
    }
}

