.home {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-top: 60px;

    img {
        width: 86%;
    }
}

@media screen and (max-width: 1200px) {
    .home {
        padding-top: 0;
        img {
            width: 100%;
            height: auto;
            margin-top: 60px;
        }
    }
}