.presentations {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #dad3c0;
    background-color: black;

    h2 {
        margin-top: 65px;
        width: 60%;
        text-align: center;
    }

    .containerPresentations {
        display: flex;
        width: 95%;
        ul {
            list-style-type: none;

            li {
                font-weight: 600;
                &:before {
                    content: "-" + " ";
                }
            }
        }
    }

    .screeenPresentations {
        display: flex;
        justify-content: start;
        flex-direction: column;
        width: 95%;
        margin-bottom: 40px;

        h2 {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .mobilePresentations {
        display: none;
    }
}


@media screen and (max-width: 1200px) {
    .presentations {
        h2 {
            font-size: 18px;
        }

        .screeenPresentations {
            display: none;
        }

        .mobilePresentations {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            ul {
                width: 90%;
                font-size: 14px;

                li {
                    width: 90%;
                }
            }
        }
    }
}

