.videos {
    width: 100%;
    margin-bottom: 40px;

    h2 {
        margin-right: auto;
        margin-left: auto;
    }

    .video {
        margin: 10px;
        background-color: #640f17;
        color: #fff5eb;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;

        h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: small;
        }
    }

    .screenVideos {
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            display: inline-flex;
        }

        .carousel {
            .rec.rec-arrow {
                border-radius: 10px;
                background-color: #640f17;
                margin: 10px;
                color: #fff5eb;

                &:disabled {
                    visibility: hidden;
                }
            }

            .rec-dot {
                background-color: #41090f;

                &:hover {
                    box-shadow: none;
                    transition: 0.3s;
                    background-color: rgb(168, 76, 83);
                }
            }

            .rec-dot_active {
                box-shadow: none;
                background-color: #fff5eb;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .videos {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;

        .screenVideos {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-bottom: 20px;

            .carousel {
                .rec.rec-arrow {
                    margin: 0;
                    display: none;
                }
                .rec-dot {
                    margin-top: 0;
                    margin-bottom: 10%;
                }
            }
            .video {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;

                h3 {
                    margin: auto;
                    padding-bottom: 2%;
                    font-size: 14px;
                    width: 270px;
                }

                iframe {
                    margin-top: 20px;
                    width: 290px;
                    height: 200px;
                }
            }
        }
    }
}
