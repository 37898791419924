@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

 html {
    scroll-behavior: smooth;
} 