.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-weight: 600;

    button {
        background-color: #41090f;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            width: 10px;
        }
    }

    button[disabled] {
        background-color: #3f1a1e;
        cursor: no-drop;
    }

    input {
        height: 19px;
        width: 25px;
        text-align: center;
        padding: 5px;
        border: none;
        border-radius: 5px;
        font-weight: 600;
    }
}

@media screen and (max-width: 1200px) {
    .pagination {
        font-size: 14px;
        margin-top: 5%;

        button {
            height: 28px;
    
            svg {
                width: 7px;
            }
        }

        input {
            height: 15px;
            width: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }

        p {
            margin-right: 10px;
        }
    }
}
